import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { Body, DesktopOnly, H4Style, MobileOnly, Reference } from '../../util/typography';

const DosingContent = styled.div`
    max-width: 1032px;
    margin: 0 auto;
    padding: 15px 20px 30px 20px;
    display: flex;
    flex-direction: column;
    color: ${colors.charcoal};
    gap: 30px;
    ${respondTo.md`
        padding: 20px 30px 30px 30px;
    `}
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
`;

const Eyebrow = styled(Body)`
`;

const Title = styled.div`
    ${H4Style};
    lenlink {
        font-weight: 700;
        color: ${colors.mulberry};
    }
`;

const Columns = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    flex-direction: column;
    ${respondTo.md`
        flex-direction: row;
    `}
`;

const Column1 = styled.div`
     display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
    > .columnHeader {
    font-size:22px;
        ${respondTo.md`
            font-size:24px;
        `}
    }
    div:nth-child(3) {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    li::marker, li::before {
        background-color: ${colors.mulberry};
    }
`;

const Column2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
    div:nth-child(3) {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
        > .columnHeader {
    font-size:22px;
        ${respondTo.md`
            font-size:24px;
        `}
    }
    li::marker, li::before {
        background-color: ${colors.mulberry};
    }
`;

const SupportingCopy = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const DosingRef = styled(Reference)`
    display: flex;
`;

const Dosing2ColumnText = ({children, className, eyebrow, title, imgSrc, imgAlt='', desktopImgSrc}) => {
    return (
        <DosingContent className={className}>
            <Header>
                {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
                {title && <Title>{children[0]}</Title>}
            </Header>
            <Columns>
                <Column1>
                <div className='columnHeader' style={{color: colors.brandGreen, fontWeight: 'bold', lineHeight: '26px'}}>{children[0]}</div>
                <div>{children[1]}</div>
                <div>{children[2]}</div>
                   </Column1>
                <Column2>
                    <div className='columnHeader' style={{color: colors.mulberry, fontWeight: 'bold'}}>{children[3]}</div>
                    <div>{children[4]}</div>
                    <div>{children[5]}</div>
                </Column2>
            </Columns>
        </DosingContent>
)};
export default Dosing2ColumnText;
